.main {
  margin: 2%;
  padding: 2%;
  background-color: #ffff;
  border-radius: 5px;
}
.right-content {
  background-color: #f4f6f9;
}
.table thead th {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
}
.table thead th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.table:not(.table-sm) thead th {
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0.04);
  color: #666;
  padding-top: 15px;
  padding-bottom: 15px;
}
.table.table-bordered td,
.table.table-bordered th {
  border-color: #f6f6f6;
}
.table.table-md th,
.table.table-md td {
  padding: 10px 15px;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
th {
  text-align: inherit;
}
.act-btn {
  padding: 2px 6px !important;
}
.view-table th {
  background-color: #d3d3d3 !important;
}
//
.table .sub-table {
  width: 100% !important;
  border-collapse: collapse !important;
}

.table .sub-table td {
  padding: 0.5rem !important;
  border-top: 1px solid #dee2e6 !important;
}

.table .sub-table tr:last-child td {
  border-bottom: 1px solid #dee2e6 !important;
  max-height: 150px !important; /* Add max-height and overflow properties for scrolling */
  overflow-y: auto !important;
}

/* Optional: Add border-top for tbody if it's not the first one */
.table tbody + tbody {
  border-top: 2px solid #dee2e6 !important;
}
//

/* pagination.scss */

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin: 0 4px;
  cursor: pointer;
  user-select: none;
}

.pagination-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px; /* Makes the entire box clickable */
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination-box:hover {
  background-color: #FE6C00; /* Hover color */
  color: white; /* Hover text color */
}

.pagination .active .pagination-box {
  background-color: #FE6C00; /* Active background */
  color: white; /* Active text color */
  border-color: #FE6C00;
}

.pagination .disabled .pagination-box {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-box a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  width: 100%; /* Ensures the anchor tag fills the box */
  height: 100%;
}
