.main {
  margin: 2%;
  padding: 2%;
  background-color: #ffff;
  border-radius: 5px;
}
.right-content {
  background-color: #f4f6f9;
}
.table thead th {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
}
.opto{
  position: relative;
    right: 290px;
}
.opto-1{
  max-height: 500px;
    overflow-y: scroll;
    // border: 1px solid gray;
    padding: 20px;
}
.opto-2{
  max-height: 200px;
    overflow-y: scroll;
    // border: 1px solid gray;
    padding: 20px;
}
.down-arrow {
  -webkit-appearance: listbox; /* Chrome, Safari */
  -moz-appearance: listbox; /* Firefox */
  -ms-appearance: listbox; /* Internet Explorer */
  appearance: listbox; /* Standard */
}
.table thead th {
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}
.table:not(.table-sm) thead th {
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0.04);
  color: #666;
  padding-top: 15px;
  padding-bottom: 15px;
}
.table.table-bordered td, .table.table-bordered th {
  border-color: #f6f6f6;
}
.table.table-md th, .table.table-md td {
  padding: 10px 15px;
}
.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}
.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
th {
  text-align: inherit;
}
.act-btn { 
  padding: 2px 6px !important;
}
.next-previous{
  text-align: center;
}
.next-go{
  padding: 10px;
  font-weight: 600;
  font-size: 18px;
  margin: 10px;
}
.previous-go{
  padding: 10px;
  font-weight: 600;
  font-size: 18px;
  margin: 10px;
}