/* Navigation Links */
.header-nav {
  display: flex;
  gap: 20px;
  align-items: center;
}

.header-nav-link {
  font-size: 1rem;
  color: #374151;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.header-nav-link:hover {
  color: #111827;
}

/* Button Styles */
.header-nav-button {
  padding: 8px 16px;
  font-size: 1rem;
  border-radius: 6px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

/* Create Menu Button */
.create-menu-button, .login-button{
  background-color: #FFAA33;
  color: #111827;
  border: none;
  padding: 8px 20px;
  margin-left: 10px;
}

.create-menu-button:hover {
  background-color: orange; /* Darker green */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: white;
}
.login-button:hover {
  background-color: orange; /* Darker green */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: white;
}



/* Hamburger and Cross Icon (for small screens) */
.hamburger-icon {
  font-size: 3rem;
  color: orange;
  cursor: pointer;
  display: none; /* Initially hidden */
}

/* Dropdown menu (for small screens) */
.dropdown-menu {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 60px; /* Position it below the header */
  right: 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px;
  width: 200px;
}

.dropdown-link {
  padding: 10px;
  font-size: 1rem;
  color: #374151;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.dropdown-link:hover {
  background-color: #f3f4f6;
  color: #111827;
}

/* Responsive Design */
@media (max-width: 1025px) {
  .nav-links,
  .nav-buttons {
      display: none; /* Hide links and buttons on small screens */
  }

  .hamburger-icon {
      display: block; /* Show hamburger icon on small screens */
      /* padding-right: 33px; */
  }
}

@media (min-width: 1025px) {
  .dropdown-menu {
      display: none; /* Hide dropdown on larger screens */
  }
}
