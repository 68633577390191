.a-tag{
    color: #4c4c4c;
}

@media (max-width: 767px) {
  .footer-links {
    
    justify-content: left;
  }
  .cef{
    justify-content: left;
  }
}
@media (min-width: 767px) {
  .footer-links {
    
    justify-content: center;
    
  }
  .cef{
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .footer-divider {
    display: block;
    height: 3px; /* Increased thickness */
    background: #ccc; /* Divider color */
    margin: 15px 0; /* Adjust spacing above and below the divider */
  }
}
.footer-links {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-top: 20px;
  }
  .footer-line{
    margin-bottom: 20px;
  }
  .transperent-hr{
    background-color: 00FFFFFF;
  }
  /* Basic reset */
.homefooter * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.homefooter {
  /* background-color: white; */
   /* White background */
  padding: 20px 0;
  font-family: 'Arial', sans-serif;
  color: #333; /* Dark text for contrast */
}

.homefooter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  padding: 20px 0;
}

.homefooter-section {
  flex: 1 1 300px;
  text-align: center;
  margin: 10px;
}

.homefooter-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.homefooter-section a {
  font-size: 16px;
    font-weight: 700;
    padding-bottom: 5px;
    position: relative;
}

.homefooter-social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.homefooter-social-icons a {
  font-size: 24px;
  color: #007BFF; /* Same color for social icons */
}

.homefooter-bottom {
  text-align: center;
  padding: 10px 0;
  /* background-color: #f4f4f4; */
  font-size: 14px;
  margin-top: 20px;
}

.homefooter-bottom p {
  margin: 0;
}

.homefooter-bottom strong {
  color: #007BFF;
}

/* Responsive Design */
@media (max-width: 768px) {
  .homefooter-container {
    /* flex-direction: column; */
    text-align: center;
  }

  .homefooter-section {
    margin-bottom: 20px;
  }
}
.footer-container{
  padding: 10px;
}
.footer-icon{
  color: #4c4c4c;
}

