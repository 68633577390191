
.App{
    text-align: center;
    align-items: center;
}
.img{
    max-width: 150px;
    margin: 2px;
}
.bg{
    background-image: url(./img/restaurant-bg.jpg);
    background: linear-gradient(0.25turn, #380a0a, #3c1504, #6c2f13);
    width: 100%;
    height: 100vh;
    background-size: cover;
    margin-top: 60px;
}
.call{
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    left: 35%;
    top: 35%;
    padding: 10px;
}
.btn-Res{
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;
    background-color: green;
    left: 10%;
    padding: 5px;
}
.btn-1{
    background-color: green;
    width: 130px;
    height: 35px;
    border-radius: 5px;
    margin-left: 100px;
}
.para{
    padding: 10px;
}
@media(min-width:576px){
    .btn-1{
        top: 20px;
        margin: 0 auto;
    

    }
    .call{
        position: sticky;
    }
    }
@media(min-width:768px){
        .btn-1{
            right: 20px;
        }
        .call{
        
            position: sticky;

        }
        .btn-1{
            /* margin-left: 310px; */
            margin: 0 auto;
        }
        }    
@media(min-width:992px){
        .call{
            text-align: center; 
        }
        .btn-1{
            /* margin-left: 432px; */
            margin: 0 auto;
        }
        } 
@media(min-width:1200px){
            .call{
                text-align: center; 
            }
            .btn-1{
                /* margin-left: 680px; */
                margin: 0 auto;
            }
            }       