.ingredients {
  color: #000;
  text-align: center;
}

.menu_name {
  color: rgb(254, 108, 0) !important;
  font-size: x-large !important;
}

.price {
  color: rgb(18, 153, 1);
  font-weight: 400;
}
.offer_price {
  color: rgb(18, 153, 1);
  font-weight: 800;
  
}
.strike-through-only-red {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.strike-through-only-red::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px; /* Adjust the thickness of the line */
  background-color: red;
  top: 55%;
  left: 0;
  transform: translateY(-50%);
}

.category-heading {
  color: rgb(254, 108, 0);
}
.category-desc {
  color: rgb(18, 153, 1);
}

.line {
  color: rgb(254, 108, 0) !important;
  background: rgb(254, 108, 0) !important;
  margin-top: 0px !important;
}

.menu-item-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.dish-img {
  padding: 10px !important;
  /* background: lightgray !important; */
}

.cursor-pointer {
  cursor: pointer;
}

.dish-img-details {
  text-align: center !important;
}
#watermark {
color: rgba(128, 128, 128, 0.3); /* Light gray color with transparency */
height: 100%; /* Full height of the viewport */
left: 0; /* Align to the left */
line-height: 2; /* Space between lines */
margin: 0; /* Remove any margin */
position: fixed; /* Fixed position to stay in view when scrolling */
top: 0; /* Align to the top */
transform: rotate(-30deg); /* Rotate the watermark */
transform-origin: 0 100%; /* Set the origin for rotation */
width: 100%; /* Full width of the viewport */
word-spacing: 10px; /* Spacing between words */
z-index: -1; /* Ensure it is behind other content */
pointer-events: none; /* Make sure it doesn't block interaction with other elements */
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
-khtml-user-select: none; /* Konqueror HTML */
-moz-user-select: none; /* Old versions of Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
}
 .custom-dish-card {
  box-shadow: 5px 5px 10px rgb(223, 220, 220);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  height: 400px;
  position: relative; /* Ensure child elements can use absolute positioning */
overflow: hidden; /* Prevent content overflow */
}

.custom-dish-card:hover {
  transform: translateY(-5px);
}

/* .card-img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
  height: 250px ;
  object-position: center;
} */

.custom-dish-card-body {
  padding: 1.25rem;
}

.custom-dish-dish-name {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}




.section-header {
display: flex;
align-items: center;
justify-content: space-between;
}

.image-container {
flex: 2;
}

.content-container {
flex: 1;
padding-left: 20px;
}

/* Media query for responsiveness on small screens */
@media (max-width: 768px) {
.section-header {
  flex-direction: column; /* Stack elements vertically */
  text-align: center; /* Center text for better mobile view */
}

.image-container {
  width: 100%; /* Ensure image takes full width */
  margin-bottom: 15px; /* Add space between image and content */
}

.menu-img {
  width: 100%; /* Let image take up full width */
  height: auto; /* Adjust height automatically */
}

.content-container {
  padding-left: 0; /* Reset padding for mobile */
}
}

/* Flexbox layout for responsive card grid */
.row {
display: flex;
flex-wrap: wrap;
}

/* General styles for the cards */
.col-lg-3, .col-md-4, .col-sm-6 {
padding: 5px; /* Add padding around each card for spacing */
box-sizing: border-box;
}

/* Small screens (max-width: 576px) - 2 cards per row */
@media (max-width: 576px) {
.col-sm-6 {
    flex: 0 0 50%; /* Each card takes up 50% width for 2 per row */
    max-width: 50%;
}
.card-img {
  /* width: 100px; */
  height: 110px;
  /* object-fit: cover; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dish-name {
color: black !important;
font-weight: 800;
font-size: small;
}
.custom-dish-card-body {
padding: 0.7rem;
}
.sticky {
position: fixed;
top: 50px;
background-color: white; /* Match background for seamless effect */
width: calc(100% - 10px); /* Adjust based on parent padding/margin */
left: 50%; /* Center the sticky element */
transform: translateX(-50%); /* Adjust position back to the center */
max-width: 1272px; /* Optional: Add a max-width for responsiveness */
align-items: center;
z-index: 100; /* Make sure it’s above other content */
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for effect */
}
.menu .nav-link h4 {
font-size: 15px;
}
.custom-dish-card-text{
font-size: smaller;
}
.offer_price{
font-size: smaller;
}
.offer-price-overlay {
font-size: 10px;
}
}

/* Small screens (max-width: 576px) - 1 card per row */
@media (max-width: 576px) {
  .col-sm-6 {
    flex: 0 0 100%; /* Each card takes full width */
    max-width: 100%;
    padding: 10px; /* Add more padding for better spacing */
  }

  .custom-dish-card {
    height: auto; /* Let height adjust based on content */
    max-width: 100%; /* Ensure card takes full width */
    margin: 0 auto 15px; /* Center card and add bottom margin */
  }

  .card-img {
    height: 200px; /* Increased height for better visibility */
    width: 100%;
    object-fit: cover;
  }

  .custom-dish-card-body {
    padding: 1rem;
  }

  .dish-name {
    font-size: 16px; /* Slightly larger font for better readability */
    line-height: 1.3;
  }
}

/* Medium screens (min-width: 577px and max-width: 992px) - 3 cards per row */
@media (min-width: 577px) and (max-width: 992px) {
.col-md-4 {
    flex: 0 0 33.3333%; /* Each card takes up 33.33% width for 3 per row */
    max-width: 33.3333%;
}
.dish-name {
  color: black !important;
  font-weight: 800;
  font-size: medium;
}
.card-img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* object-fit: cover; */
  height: 250px ;
  object-position: center;
}
.sticky {
  position: fixed;
  top: 64px;
  background-color: white; /* Match background for seamless effect */
width: calc(100% - 10px); /* Adjust based on parent padding/margin */
left: 50%; /* Center the sticky element */
transform: translateX(-50%); /* Adjust position back to the center */
max-width: 1272px; /* Optional: Add a max-width for responsiveness */
  z-index: 100; /* Make sure it’s above other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for effect */
}

.offer-price-overlay {
  font-size: 16px;
}

}

/* Large screens (min-width: 993px) - 4 cards per row */
@media (min-width: 993px) {
.col-lg-3 {
    flex: 0 0 25%; /* Each card takes up 25% width for 4 per row */
    max-width: 25%;
}
.card-img {
  width: 100%;
  height: 250px;
  /* object-fit: cover; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: block;
}
.dish-name {
color: black !important;
font-weight: 700;
font-size: large;
}
.price {
font-weight: 400 !important;  
}
.sticky {
position: fixed;
top: 64px;
z-index: 100;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
background-color: white; /* Match background for seamless effect */
width: calc(100% - 10px); /* Adjust based on parent padding/margin */
left: 50%; /* Center the sticky element */
transform: translateX(-50%); /* Adjust position back to the center */
max-width: 1272px; /* Optional: Add a max-width for responsiveness */
}
.offer-price-overlay {
font-size: 18px;
}
}



.category-tabs button {
padding: 10px 20px;
margin: 5px;
font-size: 16px;
background: #f4f4f4;
border: none;
cursor: pointer;
transition: background 0.3s ease;
}

.category-tabs button:hover {
background: #e0e0e0;
}

.category-tabs .active-tab {
background: rgb(254, 108, 0);
color: #fff;
font-weight: bold;
}

.custom-width-Dishtable th {
width: 50%;
background: white;
overflow: hidden; /* Ensure the corners of the content also follow the radius */
border-collapse: separate; /* Ensure the border radius is visible */
}

.offer-price-overlay {
position: absolute;
top: 0px;
left: 0px;
background-color: rgba(255, 255, 255, 0.7);
padding: 5px 10px;
border-radius: 5px;
display: flex;
align-items: center;
font-weight: bold;
color: black;
z-index: 10; /* Ensure it stays above the image */
}


.catactivetab{
  color: white !important; /* Change text color to white */
  font-weight: bolder;
  position: relative;
  /* background-color: rgb(254, 108, 0); Add background color */
  padding: 5px 10px;
  border-radius: 4px;
}

.catactivetab::after {
  display: none; /* Remove the underline */
}

.cat:hover {
  color: rgb(254, 108, 0); /* Same color as active state */
}
.catimg {
  width: 120px; /* Set a fixed width */
  text-align: center; /* Center align content */
}

.catimg p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Restrict text to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis (...) */
  white-space: normal;
  margin-top: 5px; /* Reduced from 10px to 5px */
  margin-bottom: 0; /* Remove bottom margin */
  font-size: 15px;
  font-weight: bolder;
  font-family: var(--font-secondary);
}

/* Small screens (max-width: 576px) - 2 cards per row */
@media (max-width: 576px) {
  .hero-img {
    height: 180px; /* Smaller images for mobile */
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .hero-logo{
    width: 120px;
    height: 120px;
    margin-top: -70px;   
}
}

/* Medium screens (min-width: 577px and max-width: 992px) - 3 cards per row */
@media (min-width: 577px) and (max-width: 992px) {
  .hero-img {
    height: 240px; /* Larger images for tablets and up */
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .hero-logo{
    width: 200px;
    height: 200px;
    margin-top: -120px;   
}
  
}

/* Large screens (min-width: 993px) - 4 cards per row */
@media (min-width: 993px) {
  .hero-img {
    height: 370px; /* Larger images for tablets and up */
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .hero-logo{
    width: 300px;
    height: 300px;
    margin-top: -200px;   
}
.cuisine-tag{
  font-size: "1.2rem";
}
.branch-name{
  font-size: "1.8rem";
  color: green;
}
.restaurant-name{
  font-size: "2.5rem";
  color: black;
}
}

.unique-dish-card-body {
  /* font-family: mulish, sans-serif; */
  padding: 5px;
  position: relative;
}

.unique-dish-card-title {
  font-size: 17px;
  font-weight: 700;
  margin: 0 0 8px;
  color: #1E1E1E;
  padding-top: 13px;
}

.unique-dish-price {
  font-size: 14px;
    color: #007E00;
    font-weight: 700; 
  margin: 0 0 8px;
}

.unique-dish-details {
  font-size: 12px;
    color: #1E1E1E;
    margin: 0 0 8px;
    font-weight: 400;
}

.unique-dish-description {
  font-size: 11px;
    font-weight: 400;
    color: #666666;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.veg-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  border: 2px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.dish-veg-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  border: 2px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  top: 3px; /* Adjust for desired spacing from the top */
  right: 3px; /* Adjust for desired spacing from the right */
}

.circle {
  width: 10px; /* Adjust size as needed */
  height: 10px;
  background-color: green;
  border-radius: 50%;
}

.nonveg-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.dish-nonveg-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  position: absolute;
  top: 3px; /* Adjust for desired spacing from the top */
  right: 3px; /* Adjust for desired spacing from the right */
}

.triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent; /* Adjust size as needed */
  border-right: 8px solid transparent; /* Adjust size as needed */
  border-bottom: 14px solid red; /* Triangle color */
}

.dish-view-table tbody tr th {
  background: white !important;
  border-radius: 10px;
}

.hero-section {
  background-image: url('./img/menu/Vector1.png') !important;
  background-position: center !important; /* Adjust as needed */
  background-repeat: space !important;
  background-size: auto 120px !important; /* Adjust the second value to increase spacing */
}

.regular-menu-search{
  width: 250px;
}

.left-scroll-btn { 
  left: 0;
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
}
.right-scroll-btn {
  right: 0;
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
}

@media (max-width: 993px) {
  .left-scroll-btn, .right-scroll-btn {
    display: none;
  }
}

/* Update the scroll-menu-container styles */
.scroll-menu-container {
  position: relative;
  display: flex;
  align-items: center;
  background: white;
  transition: all 0.3s ease;
  width: calc(100% - 10px);
  max-width: 1272px; /* Match the max-width of card container */
  margin: 0 auto;
  padding: 2px 0;
}

.scroll-menu-container.sticky {
  position: fixed;
  top: 64px;
  background-color: white;
  width: calc(100% - 10px);
  max-width: 1272px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 2px 0;
  border-radius: 4px; /* Added border-radius */
}

/* Update scroll menu wrapper */
.scroll-menu-wrapper {
  flex: 1;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 15px; /* Add padding to account for scroll buttons */
}

/* Update scroll buttons positioning */
.left-scroll-btn { 
  left: 15px;
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.right-scroll-btn {
  right: 15px;
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
}

/* Update media queries */
@media (max-width: 576px) {
  .scroll-menu-container,
  .scroll-menu-container.sticky {
    width: calc(100% - 10px);
    padding: 5px 0;
  }
}

/* Update the nav-link styles for the category items */
.nav-link {
  padding: 5px !important; /* Reduce padding around nav items */
}

.catactivetab {
  color: white !important;
  font-weight: bolder;
  position: relative;
  /* background-color: rgb(254, 108, 0); */
  padding: 5px 10px;
  border-radius: 4px; /* Updated border-radius to match */
}

.catactivetab::after {
  display: none; /* Remove the underline */
}

.nav-link:hover p {
  color: rgb(254, 108, 0);
}

.nav-link.active p {
  color: white !important;
}

/* Update mobile-specific styles */
@media (max-width: 576px) {
  .scroll-menu-container,
  .scroll-menu-container.sticky {
    width: 100%;
    padding: 2px 0;
    margin: 0;
  }

  .scroll-menu-wrapper {
    padding: 0 5px;
  }

  .catimg {
    width: 90px; /* Reduced from 100px */
    text-align: center;
  }

  .catimg img {
    width: 45px; /* Reduced from 50px */
    height: 45px; /* Reduced from 50px */
    border-radius: 50%;
    margin: 0 auto; /* Center the image */
    display: block;
  }

  .catimg p {
    font-size: 12px;
    margin-top: 2px;
    -webkit-line-clamp: 1;
    width: 100%; /* Take full width of parent */
    text-align: center;
    padding: 0 2px;
    box-sizing: border-box;
  }

  .nav-link {
    padding: 2px !important;
  }

  .catactivetab {
    padding: 3px 8px; /* Increased horizontal padding */
    border-radius: 3px;
    width: 90px; /* Reduced from 100px to match new catimg width */
    display: inline-block;
    text-align: center;
  }

  .nav-link.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px; /* Reduced from 100px to match new catimg width */
  }

  .nav-link.active img {
    width: 45px; /* Reduced from 50px */
    height: 45px; /* Reduced from 50px */
    object-fit: cover; /* Ensure square images are properly scaled */
  }

  .scroll-menu-container.sticky {
    top: 56px; /* Adjust for smaller mobile header */
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
}

/* Update tablet-specific styles */
@media (min-width: 577px) and (max-width: 992px) {
  .scroll-menu-container,
  .scroll-menu-container.sticky {
    width: calc(100% - 10px);
    padding: 2px 0;
  }

  .catimg {
    width: 100px;
  }

  .catimg img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .catimg p {
    font-size: 13px;
    margin-top: 3px;
  }
}

/* Add or update custom-dish-card-title styles */
.custom-dish-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.75rem;
  width: 100%;
  line-height: 1.2;
}

/* Update mobile-specific styles */
@media (max-width: 576px) {
  .custom-dish-card-title {
    font-size: small;
  }
}

/* Update tablet-specific styles */
@media (min-width: 577px) and (max-width: 992px) {
  .custom-dish-card-title {
    font-size: medium;
  }
}

/* Update desktop styles */
@media (min-width: 993px) {
  .custom-dish-card-title {
    font-size: large;
  }
}
.nav-tabs .nav-link.custom-active {
  background-color: transparent !important;
  color: #FF5A00 !important;
}

.nav-tabs .nav-link.custom-active p {
  color: #FF5A00 !important;
}

.catactivetab.custom-catactivetab {
  color: #FF5A00 !important;
}