.btnregistration{
    font-size: 14px;
    color: #ce1212;
    background: var(--color-primary);
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 50px;
    transition: 0.3s;
}
.days-section {
    max-height: 250px;
    overflow-y: scroll;
    border: 1px solid gray;
    padding: 20px;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
 }
/* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1;
}.pill{
    text-decoration: none;
    right: 10px;
    margin-left: 26px!important;
    padding: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: #bec4c4;
}
.res{
   
}
.align-middle{
    margin-left: 25px;
}

/* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.update-rest{
    padding: 30px 30px 0px 30px;
}
// @media (max-width: 575px){
//     .book-a-table .php-email-form {
//         padding: 58px;}
//     .p{
//         padding: 3rem !important;
//     }    
// }

// .header{
//     background: white;
// }
