.btn-color{
  background-color: orange !important;
  color: #fff;
}
.btn-color:hover{
  background-color: orange !important;
  color: #fff;
}
.profile-image-pic{
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.cardbody-color{
  background-color: #ffffff;
  border-radius: 5px;
}
a{
  text-decoration: none;
}
.footer {
  font-size: 14px;
  // background-color: #1f1f24;
  padding: 50px 0;
  color: black;
}

.footer .icon {
  margin-right: 15px;
  font-size: 24px;
  line-height: 0;
}

.footer h4 {
  font-size: 25px;
  font-weight: bold;
  position: relative;
  padding-bottom: 5px;
  color: #4c4c4c;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: #fff;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  // border-radius: 50%;
  // border: 1px solid black;
  font-size: 30px;
  color: black;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: red;
  border-color: red;
}

.footer .copyright {
  // text-align: center;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 767px) {
  .footer .copyright {
    text-align: left;
    
  }
}
@media (min-width: 767px) {
  .footer .copyright {
    text-align: center;
    
  }
}

.footer .credits {
  padding-top: 4px;
  text-align: center;
  font-size: 13px;
}

.footer .credits a {
  color: #fff;
}