.ingredients {
    color: #000;
    text-align: center;
}

.menu_name {
    color: rgb(254, 108, 0) !important;
    font-size: x-large !important;
}


.price {
    color: rgb(18, 153, 1);
    font-weight: 800;
}

.hero {
    background: transparent !important;
}

.line {
    color: rgb(254, 108, 0) !important;
    background: rgb(254, 108, 0) !important;
    margin-top: 0px !important;
}

.menu-item-margin {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.section-menu {
    padding: 12px 0 !important;
    ;
}

.dish-img {
    padding: 10px !important;
    /* background: lightgray !important; */
}

.cursor-pointer {
    cursor: pointer;
}

.dish-img-details {
    text-align: center !important;
}
