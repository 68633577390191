
.rest{
    display: flex !important;
}
.sidebar .nav-link {
  color: #fff;
  padding: 13px;
}
.sidebar .nav-pills li:hover {
  background: #0d6efd;
  color: #fff !important;
  border-radius: 5px;
}
.sidebar .nav-pills li:hover .nav-link {
  color: #fff !important;
}
.p-2{
  padding-right: 1.2rem !important;
}
.pro{
  font-size: 1.4rem;
}

.nav_link{
  color: white;
}
/* Fixed sidenav, full height */
.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn {
  /* padding: 6px 8px 6px 16px; */
  text-decoration: none;
  font-size: 16px;
  /* color: #818181; */
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  color: #f1f1f1;
}

/* Main content */
.main {
  margin-left: 200px; /* Same as the width of the sidenav */
  font-size: 20px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

/* Add an active class to the active dropdown button */
.active {
  /* background-color: #c22525; */
  color: white;
}
.sub-cat{
  padding: 10px;
}
.menu-mgmt{
  display: flex !important;
}

.dropdown-item{
  padding: 0px 30px !important;
}
/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  
  background-color: #262626;
  padding-left: 8px;
}
.more-btn{
  background-color: red;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}
.pb-2{
  padding-bottom: 0px;
}
.nav_list {
  height: calc(100vh - 100px); /* Adjust height as needed */
  overflow-y: auto;
}
@media only screen and (max-width: 768px) {

}