.main {
  margin: 2%;
  padding: 2%;
  background-color: #ffff;
  border-radius: 5px;
}
.right-content {
  background-color: #f4f6f9;
}
.table thead th {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
}
.table thead th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.table:not(.table-sm) thead th {
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0.04);
  color: #666;
  padding-top: 15px;
  padding-bottom: 15px;
}
.table.table-bordered td,
.table.table-bordered th {
  border-color: #f6f6f6;
}
.table.table-md th,
.table.table-md td {
  padding: 10px 15px;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
th {
  text-align: inherit;
}
.act-btn {
  padding: 2px 6px !important;
}
.view-table th {
  background-color: #d3d3d3 !important;
}
//
.table .sub-table {
  width: 100% !important;
  border-collapse: collapse !important;
}

.table .sub-table td {
  padding: 0.5rem !important;
  border-top: 1px solid #dee2e6 !important;
}

.table .sub-table tr:last-child td {
  border-bottom: 1px solid #dee2e6 !important;
  max-height: 150px !important; /* Add max-height and overflow properties for scrolling */
  overflow-y: auto !important;
}

/* Optional: Add border-top for tbody if it's not the first one */
.table tbody + tbody {
  border-top: 2px solid #dee2e6 !important;
}
//

/* pagination.scss */

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  // position: fixed;
  // bottom: 100px;

  .disabled {
    opacity: 0.5;
  }
  li {
    display: inline-block;
    margin: 0 4px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    cursor: pointer;
    user-select: none;

    &.active {
      background-color: #c22525;
      color: #fff;
      border-color: #c22525;
    }

    &:hover {
      color: #fff;
      background-color: #c22525;
    }
  }

  .break-me {
    cursor: default;
    user-select: none !important;
  }

  .previous,
  .next {
    display: inline-block;
    margin: 0 4px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    cursor: pointer;
    user-select: none;
    text-decoration: none;

    &:hover {
      background-color: #c22525;
      text-decoration: none;
    }
    &:hover a {
      color: #ffffff !important;
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
}
.row-padding {
  padding: 0px !important;
}
