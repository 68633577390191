@import url("https://fonts.googleapis.com/css2?display=swap&family=Nunito:wght@400;600;700&display=swap");

:root {
  --header-height: 3rem;
  --nav-width: 84px;
  --first-color: #111111;
  --first-color-light: #AFA5D9;
  --white-color: #F7F6FB;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100
}

.body-area {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: .5s
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: .5s;
  background: #fff;
}
@media (max-width: 768px) {
  .header {
    height: 70px;
  }
}
@media (max-width: 576px) {
  .header {
    height: 50px;
  }
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden
}

.header_img img {
  width: 40px
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--white-color);
  padding: .5rem 1rem 0 0;
  transition: .5s;
  z-index: var(--z-fixed)
}

.sideNav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: .5rem 0 .5rem 1.5rem;
  text-decoration: none;
}

.nav_logo {
  margin-bottom: 2rem
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color)
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700
}

.nav_link {
  position: relative;
  color: black;
//   margin-bottom: 1.5rem;
  transition: .3s;
  font-weight: 900;
}
// .l-navbar{
//   background: red;
// }
.nav_link:hover {
  color: var(--white-color);
  background: rgb(254, 108, 0);
//   color: #fff !important;
  border-radius: 5px;
}

.nav_icon {
  font-size: 1.25rem
}

.showSideNav {
  left: 0
}

.body-pd {
  transition: .5s;
  padding-left: calc(var(--nav-width) + 1rem)
}

.activeMenu {
  color: var(--white-color);
  background: rgb(254, 108, 0);
  padding-right: 20px;
  border-radius: 5px;
}

.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color)
}

.height-100 {
  height: 100vh
}
.pe-2{
  padding-right: 1.5rem !important;
}
@media screen and (min-width: 768px) {
  .body-area {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem)
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
    background: #fff;
  }

  .header_img {
    width: 40px;
    height: 40px
  }

  .header_img img {
    width: 45px
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
    background:#fff; 
  }

  .showSideNav {
    width: calc(var(--nav-width) + 180px)
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 188px)
  }
}