.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.nav-tabs .nav-link.active{
  color: white !important;
  background-color: rgb(254, 108, 0) !important;
}

.App-link {
  color: #61dafb;
}
.ant-form-item-explain-error{
  margin-top: 4px !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon{
  background-color: #d59809 !important;
    border-color: #d59809 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-menu {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  background-color: #f0f0f0;
  flex-wrap: inherit !important;
  border-radius:6px
}

.scroll-menu::-webkit-scrollbar {
  width: 0;
}
.scroll-menu::-webkit-scrollbar {
  width: 0; 
  height: 0;
}

/* .footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:dimgrey;
  color: white;
  text-align: center;
} */
.menu-img{
  height: 400px;
  width: 400px;

}
.cardView{
  border: none;
}
@media (max-width: 767px) {
  .cardView{
    border: 0.5px solid #ce1212;
    padding: 3px;
  }
.borderLeft{
  border-left: 0.5px solid #3333;
}
.menu .tab-content .menu-item {
  text-align-last: left !important;
  /* text-align-last: center; */
}
}
.page-previous{
  display: inline-block;
    margin: 0 4px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-decoration: none;
}
.page-previous .disabled{
  opacity: 0.5;
}
.page-next{
  display: inline-block;
    margin: 0 4px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-decoration: none;
}
.page-next .disabled{
  opacity: 0.5;
}
.selected-page{
  background-color: #c22525;
  color: #fff;
  border-color: #c22525;
  padding: 10px;
  cursor: pointer;
}
.not-selected-page{
  padding: 10px;
  cursor: pointer;
}

