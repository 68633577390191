@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
.backgroundGradient {
  background: linear-gradient(45deg, rgb(199, 242, 249),rgb(255, 255, 255));
  min-height: 100vh; /* Ensure background covers the entire viewport height */
  margin: 0;
  background-attachment: fixed; /* Makes the background fixed for a smooth continuous gradient */
}
/* Core styling */
.qrmenu-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 40px; */
  padding: 50px;
  /* background-color: aliceblue; */
  align-items: center;
  margin: 4%;
}

.qrmenu-content {
  max-width: 100%;
  padding: 20px;
}

.qrmenu-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  font-weight: 700; /* Adjusted for bold */
  color: #111827;
  margin-bottom: 20px;
  line-height: 1.2;
}

.qrmenu-description {
  font-size: 1.2rem;
  color: #6b7280;
  margin-bottom: 30px;
}

.qrmenu-features {
  list-style: none;
  padding-left: 0;
  font-size: 1.1rem;
  color: #374151;
}

.qrmenu-features li {
  margin-bottom: 10px;
}

.qrmenu-buttons {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Gap between buttons */
}

.create-menu-btn, .view-demo-btn {
  background-color: #ffaa33;
  border: none;
  color: #374151;
  padding: 15px 25px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
}



/* Hover effects */
.create-menu-btn:hover {
  background-color: orange;
  transform: scale(1.05);
}

.view-demo-btn:hover {
  background-color: orange;
  color: #374151;
  transform: scale(1.05);
}

/* Image styling */
.qrmenu-image {
  display: flex;
  justify-content: center;
}

.qrmenu-demo-img {
  width: 90%;
  max-width: 250px;
  border-radius: 10px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  transition: all 0.3s ease;
}

.qrmenu-demo-img:hover {
  transform: scale(1.05);
  filter: drop-shadow(5px 5px 10px #555);
  /* box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); */
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .qrmenu-heading {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
  }

  .qrmenu-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: flex-start; /* Align buttons to the left */
    justify-content: center;
  }
}

/* Responsive design for tablets */
@media (max-width: 768px) {
  .qrmenu-container {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 20px;
    margin-top: 15%;
  }

  .qrmenu-content {
    padding: 0;
  }

  .qrmenu-image {
    margin-top: 20px;
  }

  .qrmenu-demo-img {
    width: 50%;
  }

  .qrmenu-buttons {
    flex-direction: column; /* Stack buttons vertically on tablets */
    align-items: center;
    justify-content: center;
  }

  .create-menu-btn, .view-demo-btn {
    width: 100%; /* Full width on smaller screens */
    max-width: 300px; /* Limit button width */
  }
}

/* Responsive design for mobile screens */
@media (max-width: 480px) {
  .qrmenu-heading {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
  }

  .qrmenu-description {
    font-size: 1rem;
  }

  .qrmenu-features li {
    font-size: 1rem;
  }

  .create-menu-btn, .view-demo-btn {
    width: 100%; /* Ensure buttons are full width */
    padding: 12px 20px;
    font-size: 1rem;
  }
}

/* Core Container Styles */
.how-it-works-container {
  text-align: center;
  padding: 50px;
  padding-top: 0%;
  /* background-color:aliceblue; */
}

.how-it-works-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 40px;
}

/* Flexbox Layout for Steps */
.how-it-works-steps {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.how-it-works-step {
  /* background-color: white; */
  border: 2px solid #FFD580;
  border-radius: 12px;
  padding: 15px;
  width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.how-it-works-step:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px orange;
}

/* Icon Styles */
.how-it-works-icon {
  /* background-color: white;  */
  border-radius: 8px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
  color: white;
}

.how-it-works-icon img {
  width: 100%;
}

/* Step Titles */
.how-it-works-step h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 15px;
}

/* Step Description */
.how-it-works-step p {
  font-size: 1rem;
  color: #6b7280;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .how-it-works-steps {
      flex-direction: column;
      align-items: center;
  }

  .how-it-works-step {
      width: 100%;
      max-width: 400px;
      margin-bottom: 20px;
  }
  .how-it-works-container {
    padding: 20px;
    padding-bottom: 55px;
  }

}

/* Base styles for banner */
.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  padding: 20px; /* Add padding for better spacing on small screens */
  
}

.banner-card {
  background-color: #ffaa33;
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%; /* Ensure the card doesn't exceed the screen width */
}

.banner-title {
  font-size: 45px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.banner-subtitle {
  font-size: 20px;
  color: white;
  margin-bottom: 20px;
}

.banner-button {
  background-color: white;
  color: black;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner-button:hover {
  background-color: white;
  box-shadow: 10px 10px 10px rgba(2, 22, 26, 0.3);
}

/* Responsive design for tablets and smaller devices */
@media (max-width: 768px) {
  .banner-container {
    height: auto; /* Allow the container to adjust its height */
    padding: 15px;
  }

  .banner-card {
    padding: 30px; /* Reduce padding for smaller screens */
    max-width: 90%; /* Ensure card width adapts */
  }

  .banner-title {
    font-size: 35px; /* Reduce font size for the title */
  }

  .banner-subtitle {
    font-size: 18px; /* Adjust subtitle font size */
  }

  .banner-button {
    font-size: 18px; /* Adjust button font size */
    padding: 10px 20px; /* Adjust padding for smaller buttons */
  }
}

/* Further adjustments for very small screens (mobile phones) */
@media (max-width: 480px) {
  .banner-card {
    padding: 20px; /* Reduce padding more for very small screens */
    max-width: 100%; /* Full width on small screens */
  }

  .banner-title {
    font-size: 28px; /* Further reduce font size */
  }

  .banner-subtitle {
    font-size: 16px;
  }

  .banner-button {
    font-size: 16px;
    padding: 8px 16px;
  }
}

/* App.css */

/* Unique class names to avoid CSS conflicts */
/* Base styles for all screens */
.menu-update-container {
  display: flex;
  justify-content: center;
  width: 100%;

  transition: transform 0.8s ease, opacity 0.8s ease, filter 0.8s ease;
  opacity: 0;
  filter: blur(2px);
}
/* Odd containers come from the right */
.menu-update-container:nth-of-type(odd) {
  transform: translateX(2%); /* Start 10% from the right */
}

/* Even containers come from the left */
.menu-update-container:nth-of-type(even) {
  transform: translateX(-2%); /* Start 10% from the left */
}

/* Apply the visible state when the element is in view */
.menu-update-container.show {
  transform: translateX(0); /* Move to the original position */
  opacity: 1;
  filter: blur(0); /* No blur when visible */
}

.menu-update-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1000px;
  padding: 20px;
  /* background-color: white; */
  border-radius: 12px;
  width: 100%;
}

.menu-update-text {
  margin-bottom: 20px;
  justify-content: center;
}

.menu-update-title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 15px;
  font-family: 'Arial', sans-serif;
}

.menu-update-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  font-family: 'Arial', sans-serif;
}

.menu-update-image {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
}
.menu-update-image:hover {
  transform: scale(1.05);
  filter: drop-shadow(6px 6px 12px #555);
  /* box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); */
}

.menu-update-laptop-image {
  max-width: 60%;
  height: auto;
  border-radius: 10px;
  
}

/* Responsive design for tablet and desktop */
@media (min-width: 768px) {
  .menu-update-content {
    flex-direction: row;
    text-align: left;
  }

  .menu-update-text {
    flex: 1;
    padding-right: 20px;
  }

  .menu-update-image {
    flex: 1;
    margin-top: 0;
  }

  .menu-update-title {
    font-size: 3rem;
  }

  .menu-update-description {
    font-size: 1.2rem;
  }

  /* Alternate row content for even containers */
  .menu-update-container:nth-child(even) .menu-update-content {
    flex-direction: row-reverse; /* Reverse image and text order */
  }
}

/* Responsive design for mobile */
@media (max-width: 767px) {
  .menu-update-content {
    flex-direction: column; /* Stack text and image vertically */
  }

  .menu-update-title {
    font-size: 2rem;
  }

  .menu-update-description {
    font-size: 1rem;
  }

  .menu-update-image {
    margin-top: 10px;
  }

  .menu-update-laptop-image {
    max-width: 60%;
  }
}
