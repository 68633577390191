.contact-us {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  font-family: Arial, sans-serif;
  padding-top:10%;
  padding-left: 15%;
  padding-right: 15%;
}

.contact-info {
  flex: 1;
  margin-right: 20px;
}

.contact-info h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.contact-details p {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.contact-form {
  flex: 1;
  background-color: aliceblue;
  padding: 20px;
  border-radius: 8px;
}

.contact-form h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.form-group textarea {
  resize: none;
  height: 100px;
}

.submit-btn {
  background-color: #FFAA33;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #7cb342;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .contact-us {
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .contact-form {
    margin-bottom: 20px;
  }

  .contact-info {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .contact-info h2 {
    font-size: 1.5rem;
  }

  .contact-form h3 {
    font-size: 1.2rem;
  }
  .contact-us {
    flex-direction: column;
    align-items: center;
    padding-top:25%;
  }
}
