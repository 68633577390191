/* General Styles */
.offers-tabs {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px; /* Adds spacing between buttons */
    padding: 10px; /* Optional, for better spacing */
    scrollbar-width: thin; /* For browsers that support custom scrollbars */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.offers-tabs::-webkit-scrollbar {
    height: 8px; /* Scrollbar height */
}

.offers-tabs::-webkit-scrollbar-thumb {
    background: #cccccc; /* Scrollbar color */
    border-radius: 4px;
}

.offers-tabs .tab-button {
    flex: none; /* Prevent buttons from stretching */
    font-size: 16px;
    border: none;
    cursor: pointer;
    border: 2px solid gray;
    border-radius: 12px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Remove tap highlight */
}

.offers-tabs .tab-button.active {
    background-color: #E5F2E5;
    color: #007E00;
    font-weight: bold;
    border: 2px solid #007E00;
    border-radius: 12px;
}

.tab-button.active {
    background-color: rgb(226, 251, 226);
}

.offer-item {
    background-color: #000;
    border-radius: 10px;
    width: 100%;
    padding-top: 130%; /* 1:1 Aspect Ratio */
    position: relative;
    box-shadow: 3px 3px 6px gray;
}

.offer-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 0 0;
}

.view-more-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.view-more-button {
    background: none;
    color: black;
    border: none;
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Remove tap highlight */
}

.view-more-container::before,
.view-more-container::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #d3d3d3;
    margin: 0 10px;
}

.view-more-button:hover {
    color: #0078d4;
}

.all-restaurant-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
    float: right;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Remove tap highlight */
}

.carousel-container {
    border-radius: 10px;
    overflow: hidden; /* Ensures content inside respects the border radius */
}

.dish-container {
    text-align: center;
    padding: 20px;
}

.dish-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 20px;
    transition: transform 0.2s ease-in-out;
}

.dish-card:hover {
    transform: translateY(-5px);
}

.dish-photo {
    width: 100%;
    height: 320px;
    object-fit: cover;
}

.dish-content {
    flex: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dish-title {
    font-size: 1.25em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.dish-category {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 10px;
}

.dish-description {
    font-size: 0.95em;
    color: #555;
    margin-bottom: auto;
}

.dish-price {
    font-size: 1.1em;
    font-weight: bold;
    color: #333;
}

.all-restaurant-button {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: rgb(18, 153, 1);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.all-restaurant-button:hover {
    background-color: #555;
}

/* Responsive design */
@media (max-width: 768px) {
    .dish-card {
        margin: 10px 0;
    }

    .dish-photo {
        height: 150px;
    }

    .dish-container .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .col-md-6 {
        flex: 0 0 48%;
        max-width: 48%;
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .dish-photo {
        height: 250px;
    }

    .dish-container .row {
        flex-direction: column;
    }

    .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .all-restaurant-button {
        width: 100%;
    }
}

/* Testimonials Section */
.testimonials-section {
    padding: 60px 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.testimonials-section h1 {
    margin-bottom: 50px;
    color: #333;
    font-size: 2.5rem;
    letter-spacing: 1px;
}

.testimonial {
    background-color: #ffffff;
    padding: 40px 30px;
    margin: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    position: relative;
    width: 100%;
    max-width: 300px;
}

.testimonial:hover {
    transform: translateY(-10px);
}

.quote-icon {
    font-size: 60px;
    color: #ffc107;
    position: absolute;
    top: -20px;
    left: 20px;
}

.testimonial h3 {
    margin-top: 30px;
    font-weight: bold;
    color: #333;
    font-size: 1.5rem;
}

.testimonial p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.5;
}

.testimonial .author {
    margin-top: 20px;
    font-style: italic;
    color: #777;
}

@media (min-width: 768px) {
    .testimonial {
        width: calc(50% - 40px); /* Adjust for two columns */
    }
    .offers-tabs {
        justify-content: center;
    }
}

@media (min-width: 992px) {
    .testimonial {
        width: calc(33.333% - 40px); /* Adjust for three columns */
    }
}

/* Logo and Location Styles */
.location-container,
.logo-container,
.testimonial-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.location-item,
.logo-item,
.testimonial-item {
    margin: 10px;
}

.location-img,
.logo-img {
    width: 100%;
    max-width: 150px;
    transition: transform 0.3s;
}

.location-img:hover,
.logo-img:hover {
    transform: scale(1.1);
}

.location-name {
    text-align: center;
    font-weight: bold;
}

.offers-grid {
    display: grid;
    gap: 16px; /* Adjust spacing between cards as needed */
}

@media (min-width: 1025px) {
    .offers-grid {
        grid-template-columns: repeat(4, 1fr); /* 4 cards per row */
    }
    .offers-grid.event-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row for Event Special */
    }
}

@media (min-width: 850px) and (max-width: 1025px) {
    .offers-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    }
    .offers-grid.event-grid {
        grid-template-columns: repeat(1, 1fr); /* 2 cards per row for Event Special */
    }
}

@media (min-width: 768px) and (max-width: 850px) {
    .offers-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    }
    .offers-grid.event-grid {
        grid-template-columns: repeat(1, 1fr); /* 2 cards per row for Event Special */
    }
}

@media (max-width: 767px) {
    .offers-grid {
        grid-template-columns: repeat(1, 1fr); /* 2 cards per row */
    }
    .offers-grid.event-grid {
        grid-template-columns: repeat(1, 1fr); /* 2 cards per row for Event Special */
    }
}

/* Logo Scroll Animation */
.logo-scroll {
    display: flex;
    align-items: center;
    width: max-content;
    opacity: 0; /* Start invisible */
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000;
    will-change: transform;
    animation: fadeInAndScroll 15s linear infinite;
    animation-delay: 1s; /* Delay animation start by 1 second */
    -webkit-animation: fadeInAndScroll 15s linear infinite;
    -webkit-animation-delay: 1s;
}

.logosimg {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    max-height: 100px;
    padding: 5px;
    background-color: white;
    margin: 0 10px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

@keyframes fadeInAndScroll {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

@-webkit-keyframes fadeInAndScroll {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

.logosimg img {
    max-width: 100%; /* Ensure image does not exceed container */
    max-height: 100%; /* Ensure image does not exceed container */
    object-fit: contain; /* Ensures the logo fits within the boundary without distortion */
}

/* Search Form Styles */
.search-form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    max-width: 500px;
    margin: 20px auto;
    transition: all 0.3s ease-in-out;
}

.search-button {
    background-color: #ffaa33;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 0 8px 8px 0;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-weight: bold;
}

.search-button:hover {
    background-color: #ffaa33;
    transform: scale(1.05);
}

/* Offer Image Styles */
.offer-image {
    position: relative;
}

.icon-overlay {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    border-radius: 50%; /* Circle shape */
}

.offer-icon {
    font-size: 20px;
    color: black; /* White icon color */
}

.offer-image img {
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.bottom-section {
    display: flex;
    align-items: center;
    padding: 15px;
    text-align: center;
    height: 80px;
}

.restaurant-bottom-section {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
    height: 80px;
}

.top-section {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 15px;
}

.restaurant-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.restaurant-name {
    color: black;
    font-weight: bold;
    font-size: 12px;
    align-content: center;
}

.price-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #333;
    font-size: 18px;
}

.search-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.search-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    position: relative;
    transition: color 0.3s ease-in-out;
}

.search-input::placeholder {
    color: #888;
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
    white-space: nowrap;
    overflow: hidden;
}

@keyframes scrollPlaceholder {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    25% {
        transform: translateX(0);
        opacity: 1;
    }
    50% {
        transform: translateX(-100%);
        opacity: 0;
    }
    75% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.search-button {
    margin-left: 10px;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-button:hover {
    background-color: #45a049;
}

@media (max-width: 530px) {
    .logotext {
        display: none;
    }
}

@media (max-width: 991px) {
    .sidebaneer {
        display: none !important;
    }
}

/* Small screens */
@media (max-width: 576px) {
    .search-input {
        width: 170px;
    }
    .tab-button {
        border-color: green;
        border: 2px solid green;
        background-color: white;
        border-radius: 5px;
    }
}

/* Medium screens */
@media (min-width: 577px) and (max-width: 992px) {
    .tab-button {
        border-color: #ffaa33;
        border: 2px solid #ffaa33;
        padding: 10px 20px;
        background-color: white;
        border-radius: 5px;
        font-weight: bold;
    }
}

/* Large screens */
@media (min-width: 993px) {
    .search-input {
        width: 300px;
    }
    .tab-button {
        border-color: #ffaa33;
        border: 2px solid #ffaa33;
        padding: 10px 20px;
        background-color: white;
        border-radius: 5px;
        font-weight: bold;
    }
}

.card-bottom-section {
    padding: 10px 10px 10px 10px;
}

.event-card-horizontal {
    margin: 15px 0;
    width: 100%;
    height: 100%; /* Ensure full height */
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgb(223, 220, 220);
    transition: transform 0.3s ease-in-out;
}

.event-card-horizontal:hover {
    transform: translateY(-5px);
}

.event-card-horizontal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.event-card-horizontal-image {
    width: 100%;
    height: 600px; /* Increased height for better visibility */
    position: relative;
}

.event-card-horizontal-image img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Maintains aspect ratio without compression */
    background-color: #f5f5f5; /* Light background for better visibility */
}

/* Keep the existing grid layout but adjust for larger cards */
.offers-grid.event-grid {
    display: grid;
    gap: 20px;
    margin: 20px 0;
}

@media (min-width: 1025px) {
    .offers-grid.event-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1024px) {
    .offers-grid.event-grid {
        grid-template-columns: 1fr;
    }

    .event-card-horizontal-image {
        height: 500px; /* Slightly smaller on tablets */
    }
}

@media (max-width: 768px) {
    .event-card-horizontal-image {
        height: 400px; /* Adjusted for mobile */
    }
}

.event-card-horizontal-details {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0px; /* Reduced from 15px */
}

.event-card-horizontal-title {
    font-size: 1.25rem; /* Slightly reduced */
    font-weight: bold;
    color: #333;
    margin-bottom: 0; /* Remove bottom margin */
}

.price-and-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0; /* Minimal margin */
}

.event-card-horizontal-icons {
    display: flex;
    gap: 20px; /* Reduced from 15px */
}

.event-card-horizontal-icons a {
    display: flex;
    align-items: center;
}

.event-card-horizontal-icons img {
    width: 20px; /* Slightly smaller icons */
    height: 20px;
}

.restaurant-info {
    display: flex;
    align-items: center;
    gap: 16px; /* Reduced from 10px */
    margin-top: 0px; /* Minimal top margin */
}

.restaurant-info .restaurant-icon {
    width: 35px; /* Slightly smaller */
    height: 35px;
}

.restaurant-info .restaurant-name {
    font-size: 1.25rem; /* Slightly smaller */
    margin-bottom: 0px; /* Minimal spacing */
}

.restaurant-info .branch-name {
    font-size: 1rem;
}