.mar-main {
   background-color: #fff;
   margin-top: -150px;
}

.mar-d1 {
   /* margin-top: 30px; */
   border: 1px #fff solid;
   padding-left: 10px;
   padding-top: 10px;
   background: var(--color-orange);
   color: #fff;
   border-radius: 7px;
}

.mar-d1 h2 {
   font-size: 20px;
   font-weight: 600;
}

.notify-1 {
   margin: 10px;
   position: relative;
   text-align: justify;
   justify-content: center;
   float: inline-end;
}

.section {
   padding: 0px;
}

.mar-d1 h3 {
   font-size: 12px;
}

.mar-d2 {
   text-align: center;
   margin-top: 24px;
   background: #198754;
   padding-top: 30px;
   /* padding-bottom: 20px; */
   color: #ffd;
   width: 97%;
   height: 25vh;
   border-radius: 7px;
}

.mar-card {
   /* padding-left: 16p; */
   margin: 0 auto;
}

.mar-card1 {
   margin-right: 3px;
   border-radius: 7px;
}

.mar-ico1 {
   color: rgb(171, 186, 186);
   font-size: 35px;
   margin-bottom: 7px;
}

.mar-d2 h6 {
   opacity: 0.8;
   font-size: 17px;
}

.mar-d2 h3 {
   font-weight: 600;
   font-size: 30px;
}

.mar-d2 p {
   opacity: 0.9;
   font-size: 16px;
   margin-bottom: 7px;
}

.mar-ico2 {
   color: rgb(171, 186, 186);
   font-size: 35px;
   margin-bottom: 7px;
}

.breadcrumb {
   color: #fff;
}

.mar-ico3 {
   color: rgb(171, 186, 186);
   font-size: 35px;
   margin-bottom: 7px;
}

.mar-ico4 {
   color: rgb(171, 186, 186);
   font-size: 35px;
   margin-bottom: 7px;
}


.breadcrumb-item.breadcrumb-item::before {
   color: white !important;
}

.margn-above {
   margin-top: 20px !important;
}

/* table thead th {
   background-color: rgba(240, 248, 255, 0.5) !important;
} */
@media (max-width: 768px) {
   .row {
     flex-wrap: nowrap;
   }
   .row > .col-12 {
     flex: 1 1 auto;
     max-width: auto;
     padding: 1.5%;
   }
   .mar-d2 {
     margin-top: 10px;
     width: 100%;
     height: fit-content;
     box-sizing: border-box;
     padding-top: 10px;
     padding-bottom: 5px;
   }
 }