.borderless {
  border-collapse: collapse;
}

.borderless th,
.borderless td {
  border: none;
}
.infoTable th,
.sub-table th {
  font-size: 16px !important;
  background-color: #dcdcdc60 !important;
}
.infoTable td,
.sub-table tbody tr td {
  font-size: 16px !important;
  font-weight: 300 !important;
  background-color: #ffffff !important;
}
.rest-info{
  margin-bottom: 1.5rem;
}
.heading{
  font-size: small;
  font-weight: 800;
}
.bg-error{
  background-color: var(--color-primary);
}

