.theme{
    color: red;
    width: 20px;
    height: 20px;
}
.theme-1{
    background-color: aqua;
    width: 20px;
    height: 20px;
}
.theme-2{
    color: rgb(218, 150, 24);
    width: 20px;
    height: 20px;
}
.theme-3{
    background-color: rgb(36, 36, 168);
    width: 20px;
    height: 20px;
}
.theme-4{
    color: rgb(203, 35, 35);
    width: 20px;
    height: 20px;
}
.theme-5{
    background-color: rgb(123, 50, 123);
    width: 20px;
    height: 20px;
}
.theme-6{
    
    width: 20px;
    height: 20px;
}
.theme-7{
    background-color: #03A9F4;
    width: 20px;
    height: 20px;
}
.theme-8{
    color: #ab2854;
    width: 20px;
    height: 20px;
}
.app{
    height: 28px;
    width: 56px;
    margin: 0px;
    position: relative;
    background: #F44336 !important;
    border-radius: 14px;
    cursor: pointer;
}
