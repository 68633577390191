.ingredients {
    color: #000;
    text-align: center;
}

.menu_name {
    color: rgb(254, 108, 0) !important;
    font-size: x-large !important;
}



.price {
    color: rgb(18, 153, 1);
    font-weight: 800;
}

.hero {
    background: aliceblue !important;
}

.line {
    color: rgb(254, 108, 0) !important;
    background: rgb(254, 108, 0) !important;
    margin-top: 0px !important;
}

.menu-item-margin {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.section-menu {
    padding: 12px 0 !important;
    ;
}

.dish-img {
    padding: 10px !important;
    /* background: lightgray !important; */
}

.cursor-pointer {
    cursor: pointer;
}

.dish-img-details {
    text-align: center !important;
}
.plancard-hover {
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  .plancard-hover:hover {
    border-color: rgb(254, 108, 0);
    box-shadow: 0 0 10px rgb(254, 108, 0);
  }

.plancard {
    background-color: aliceblue;
    box-shadow: 10px 10px 10px rgba(2, 22, 26, 0.3);
    border-radius: 10px;
} 
.plancard-custom {
    max-width: 300px;
    margin: auto;
    border: none;
    
}
.plancard-header {
    background-color: rgb(254, 108, 0);;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.8em;
    padding: 0.7em 0;
    border-top-left-radius: 10px; /* Rounded top left corner */
    border-top-right-radius: 10px; /* Rounded top right corner */
}

.plancard-body {
    text-align: center;
    padding: 1.5em;
}
.plancard-body .price {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.5em;
}
.plancard-body ul {
    list-style: none;
    padding: 0;
    margin: 1em 0;
}
.plancard-body ul li {
    margin: 0.5em 0;
}
.btn-custom {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5em 2em;
    margin-top: 1em;
    font-size: 1em;
} 
