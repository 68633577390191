.modal {
z-index: 500 !important;
}
.search {
  margin-right: 10px;
}
.error-msg {
  color: red;
}

/* pagination.scss */

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  // position: fixed;
  // bottom: 100px;

  .disabled {
    opacity: 0.5;
  }
  li {
    display: inline-block;
    margin: 0 4px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    cursor: pointer;
    user-select: none;

    &.active {
      background-color:  #FE6C00 !important;
      color: #fff;
      border-color:  #FE6C00 !important;
    }

    &:hover {
      color: #fff;
      background-color:  #FE6C00 !important;
    }
  }

  .break-me {
    cursor: default;
    user-select: none !important;
  }

  .previous,
  .next {
    display: inline-block;
    margin: 0 4px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    cursor: pointer;
    user-select: none;
    text-decoration: none;

    &:hover {
      background-color:  #FE6C00 !important;
      text-decoration: none;
    }
    &:hover a {
      color: #ffffff !important;
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
}
// .map-Section {
//   max-height: 250px;
  
//   border: 1px solid gray;
//   padding: 20px;
// }
