.btn-color{
  background-color: var(--color-orange) !important;
  color: #fff;
}
.btn-color:hover{
  background-color: orange !important;
  color: #fff;
}
.profile-image-pic{
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.cardbody-color{
  background-color: #FFF;
  border-radius: 5px;
}
a{
  text-decoration: none;
}
.header .btn-book-a-table, .header .btn-book-a-table:focus {
  font-size: 14px;
  color: #fff;
  background: green;
  padding: 8px 20px;
  margin-left: 850px;
  border-radius: 50px;
  transition: 0.3s;
}