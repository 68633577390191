.btnregistration {
  font-size: 14px;
  color: #ce1212;
  background: var(--color-primary);
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  transition: 0.3s;
}
.days-section {
  max-height: 250px;
  overflow-y: scroll;
  border: 1px solid gray;
  padding: 20px;
}
.working-hours-branch {
  max-height: 250px;
  overflow-y: scroll;
  border: 1px solid #ce1212;
  padding: 20px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bec4c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.select {
  outline: none !important;
  border: none !important;
}

.book-a-table .php-email-form .ant-select-selector {
  padding: 12px 15px !important;
  height: 48px !important;
  vertical-align: middle !important;
  border-radius: 1px !important;
}
#defaultCurrencyId_help {
  margin-top: 13px !important;
}

.ant-select-disabled {
  background-color: transparent !important;
}
.btn-login{
  background: "#ce1212"; 
  color: "white";
  margin-top: 20px;
}
