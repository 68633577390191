.main {
  margin: 2%;
  padding: 2%;
  background-color: #ffff;
  border-radius: 5px;
}

.right-content {
  background-color: #f4f6f9;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: aliceblue;
}

.down-arrow {
  -webkit-appearance: listbox !important;
}

.table thead th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.table:not(.table-sm) thead th {
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0.04);
  color: #666;
  padding-top: 15px;
  padding-bottom: 15px;
}

.table.table-bordered td,
.table.table-bordered th {
  border-color: #f6f6f6;
}

.table.table-md th,
.table.table-md td {
  padding: 10px 15px;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

th {
  text-align: inherit;
}

.act-btn {
  padding: 2px 6px !important;
}

.next-previous {
  text-align: center;
}

.fil {
  top: 50px;
}

.next-go {
  padding: 10px;
  font-weight: 600;
  font-size: 18px;
  margin: 10px;
}

.previous-go {
  padding: 10px;
  font-weight: 600;
  font-size: 18px;
  margin: 10px;
}

.border-model {
  border: 1px solid lightgray !important;
  overflow: inherit !important;
}