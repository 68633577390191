.form-label {
  font-size: 15px; /* Adjust the font size as needed */
}

.custom-label {
  margin-right: 10px; // Adjust the value as needed
}

.custom-label-left{
  margin-left: 15px;
}
