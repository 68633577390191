.btn-color{
  background-color: orange !important;
  color: #fff;
}
.btn-color:hover{
  background-color: orange !important;
  color: #fff;
}
.profile-image-pic{
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.cardbody-color{
  background-color: #ffffff;
  border-radius: 5px;
}
a{
  text-decoration: none;
}